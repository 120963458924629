import React, { useEffect } from 'react';
import { ShipmentOrder, ShipmentStatus, useGetShipmentsPageQuery, useInfiniteGetShipmentsPageQuery } from '../../generated/types-and-hooks';
import log from 'loglevel';
import _ from 'lodash';
import { printedOrderSort } from './AdminActiveOrdersV2';
import { createAllPackingSlips } from './OrderPdfs';
import AdminZebraPicker from './AdminZebraPicker';
import LoadingError from '../LoadingError';
import Loading from '../Loading';

const formatTimestampAsDate = (timestamp: number) => new Date(timestamp * 1000).toLocaleString();

const printedShipmentSort = (a: ShipmentOrder, b: ShipmentOrder) => printedOrderSort(a.Order!, b.Order!);

const AdminShipments = ({ }) => {
  const shipmentsContext = useInfiniteGetShipmentsPageQuery({
    Status: ShipmentStatus.Created,
    ScanIndexForward: false,
    Limit: 250,
  },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.GetShipmentsPage?.LastEvaluatedKey) {
          return {
            ExclusiveStartKey: lastPage.GetShipmentsPage?.LastEvaluatedKey,
          };
        }
      },
    });

  // Automatically load next page if available
  useEffect(() => {
    if (!shipmentsContext.isLoading && shipmentsContext.hasNextPage) {
      log.info('fetching next page automatically');
      shipmentsContext.fetchNextPage();
    }
  }, [shipmentsContext.data]);

  const zebraPicker = AdminZebraPicker();

  const allData = _.concat(
    ..._.map(shipmentsContext.data?.pages, (page) => page.GetShipmentsPage?.Shipments || []),
  );

  const groups = _.groupBy(allData, 'Shipment.TimestampCreated');

  const printPackingSlips = async (timestampKey) => {
    const batch = groups[timestampKey] as ShipmentOrder[];
    const Orders = batch.sort(printedShipmentSort).map(({ Order }) => Order).filter((order) => order !== null && order !== undefined);
    log.debug({ timestampKey, batch, Orders }, 'printing packing slips for timestamp');
    createAllPackingSlips(Orders);
  };

  const printLabels = async (timestampKey) => {
    const batch = groups[timestampKey] as ShipmentOrder[];
    const Shipments = batch.sort(printedShipmentSort).map(({ Shipment }) => Shipment).filter((shipment) => shipment !== null && shipment !== undefined);
    log.debug({ timestampKey, batch, Shipments }, 'printing labels for timestamp');
    if (zebraPicker.ReadyToPrint) {
      zebraPicker.PrintShipments(Shipments);
    } else {
      log.error('Zebra printer not ready to print');
    }
  }

  const tableRendered = _.size(allData) === 0 ?
    <Loading /> : <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Actions</th>
          <th>Orders</th>
        </tr>
      </thead>
      <tbody>
        {_.reverse(_.map(groups, (group, key) => {

          return <tr key={key}>
            <td>{formatTimestampAsDate(key)}</td>
            <td>
              <a onClick={() => printPackingSlips(key)}>Packing&nbsp;Slips</a><br />
              <a onClick={() => printLabels(key)}>Labels</a>
            </td>
            <td>{group.map((shipment) => shipment?.Order?.InvoiceId).join(', ')}</td>
          </tr>
        }))}
      </tbody>
    </table>


  return <div>
    <h1>Shipments</h1>
    <LoadingError error={shipmentsContext.error} />
    {zebraPicker.Rendered}
    <p align='right'>{zebraPicker.StatusRendered}</p>
    {tableRendered}

  </div>
};

export default AdminShipments;